import { TimezoneType } from '@wix/bookings-uou-types';
import {
  Service,
  ServiceType,
} from '@wix/ambassador-bookings-services-v2-service/types';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import {
  getEndOfMonthAsLocalDateTime,
  getLocalTimezone,
  getStartOfMonthAsLocalDateTime,
  getTodayLocalDateTimeStartOfDay,
} from '../../../../utils/dateAndTime/dateAndTime';
import { CalendarStatus } from '../../ViewModel/widgetViewModel/widgetViewModel';
import { SetSelectedMonth } from '../setSelectedMonth/setSelectedMonth';
import { CalendarState } from '../../controller';
import { SetSelectedDate } from '../setSelectedDate/setSelectedDate';
import { TriggeredByOptions } from '../../../../types/types';
import { SetSelectedRange } from '../setSelectedRange/setSelectedRange';
import { getLocalDateTimeRangeForDay } from '../../../../utils/getLocalDateTimeRangeForDay/getLocalDateTimeRangeForDay';
import { AddError } from '../addError/addError';
import { InitializeCalendarDateOptions } from '../../../../utils/bi/consts';
import { bookingsCalendarPageLoaded } from '@wix/bi-logger-wixboost-ugc/v2';
import {
  isDailyAgendaWeeklyPickerLayout,
  isDailyTimeSlotsWeeklyPickerLayout,
  isWeeklyTimeSlotsLayout,
  isWeeklyTimetableLayout,
} from '../../../../utils/layouts';
import { AutoSelectTime } from '../autoSelectTime/autoSelectTime';
import {
  getUrlQueryParamValue,
  BookingsQueryParams as BookingsQueryParamsUtils,
} from '@wix/bookings-catalog-calendar-viewer-utils';
import { isCalendarPage, isCalendarWidget } from '../../../../utils/presets';

export type InitializeWidget = () => Promise<void>;

export function createInitializeWidgetAction(
  {
    getControllerState,
    context,
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  setSelectedDate: SetSelectedDate,
  setSelectedMonth: SetSelectedMonth,
  setSelectedRange: SetSelectedRange,
  addError: AddError,
  autoSelectTime: AutoSelectTime,
): InitializeWidget {
  return async () => {
    const {
      flowAPI,
      biLogger,
      settings,
      settingsParams,
      businessInfo,
      calendarApi,
      preset,
      isDateAndTimeViewMode,
    } = context;
    const {
      environment: { isSSR, isEditor },
    } = flowAPI;

    if (isSSR) {
      return;
    }

    const [, setState] = getControllerState();

    const selectedTimezone = getSelectedTimezone(context);
    setState({ selectedTimezone });

    const [state] = getControllerState();
    const { servicesInView } = state;

    const initialLocalDate = await getInitializeCalendarDate({
      state,
      context,
      addError,
    });

    const isCalendar = isCalendarPage(preset) || isCalendarWidget(preset);

    if (
      !isEditor &&
      isCalendar &&
      !isDateAndTimeViewMode &&
      servicesInView.every(
        (service) => service.type === ServiceType.APPOINTMENT,
      )
    ) {
      const serviceIds = servicesInView.map((service) => service.id!);

      calendarApi
        .queryServicesV2({ serviceIds })
        .then((response) => {
          const servicesWithEnrichedPolicy = enrichServicePolicy(
            servicesInView,// v1 mapped to v2
            response.services, // V2
          );

          setState({ servicesInView: servicesWithEnrichedPolicy });
        })
        .catch((error) => {
          console.error(error);
        });
    }

    if (
      isWeeklyTimeSlotsLayout(settings, settingsParams) ||
      isWeeklyTimetableLayout(settings, settingsParams)
    ) {
      const range = getLocalDateTimeRangeForDay(
        businessInfo!.dateRegionalSettingsLocale!,
        initialLocalDate,
      );
      await setSelectedRange(range, TriggeredByOptions.INITIALIZE_WIDGET);
    } else if (
      isDailyTimeSlotsWeeklyPickerLayout(settings, settingsParams) ||
      isDailyAgendaWeeklyPickerLayout(settings, settingsParams)
    ) {
      const range = getLocalDateTimeRangeForDay(
        businessInfo!.dateRegionalSettingsLocale!,
        initialLocalDate,
      );
      setSelectedRange(range, TriggeredByOptions.INITIALIZE_WIDGET);
      await setSelectedDate(
        initialLocalDate,
        TriggeredByOptions.INITIALIZE_WIDGET,
      );
    } else {
      const startOfMonthAsLocalDateTime =
        getStartOfMonthAsLocalDateTime(initialLocalDate);
      setSelectedMonth(
        startOfMonthAsLocalDateTime,
        TriggeredByOptions.INITIALIZE_WIDGET,
      );
      await setSelectedDate(
        initialLocalDate,
        TriggeredByOptions.INITIALIZE_WIDGET,
      );
    }

    autoSelectTime(TriggeredByOptions.INITIALIZE_WIDGET);

    setState({ calendarStatus: CalendarStatus.IDLE });

    biLogger.report(bookingsCalendarPageLoaded({}));
  };
}

function getSelectedTimezone({ businessInfo, flowAPI }: CalendarContext) {
  const localTimezone = getLocalTimezone();
  const preSelectedTimezone = getUrlQueryParamValue(
    flowAPI.controllerConfig.wixCodeApi,
    BookingsQueryParamsUtils.TIMEZONE,
  );

  const isPreselectedTimezoneValid = [
    businessInfo!.timeZone,
    localTimezone,
  ].includes(preSelectedTimezone);

  const defaultTimezone =
    businessInfo!.timezoneProperties?.defaultTimezone === TimezoneType.CLIENT
      ? localTimezone
      : businessInfo!.timeZone!;

  return isPreselectedTimezoneValid ? preSelectedTimezone : defaultTimezone;
}

const getInitializeCalendarDate = async ({
  state,
  context,
  addError,
}: {
  state: CalendarState;
  context: CalendarContext;
  addError: AddError;
}): Promise<string> => {
  const { selectedDate, selectedTimezone } = state;
  const { settings, settingsParams, calendarApi } = context;
  const isAnonymousCancellationFlow =
    getUrlQueryParamValue(
      context.flowAPI.controllerConfig.wixCodeApi,
      BookingsQueryParamsUtils.REFERRAL,
    ) === 'batel';

  const todayLocalDateTime = getTodayLocalDateTimeStartOfDay(selectedTimezone!);

  if (isAnonymousCancellationFlow) {
    return selectedDate || todayLocalDateTime;
  }
  if (selectedDate) {
    return selectedDate;
  }

  const shouldInitializeCalendarOnToday =
    settings.get(settingsParams.initializeCalendarDate) ===
    InitializeCalendarDateOptions.TODAY;
  if (shouldInitializeCalendarOnToday) {
    return todayLocalDateTime;
  } else {
    const sixMonthsFromNow = getEndOfMonthAsLocalDateTime(
      todayLocalDateTime,
      7,
    );
    const nextAvailableDate = await calendarApi.getNextAvailableDate(
      {
        fromAsLocalDateTime: todayLocalDateTime,
        toAsLocalDateTime: sixMonthsFromNow,
      },
      { state, context, onError: addError },
    );
    return nextAvailableDate || todayLocalDateTime;
  }
};

const enrichServicePolicy = (
  partialServicesV2: Service[],
  servicesV2?: Service[],
): Service[] => {
  const idServicesV2Map =
    servicesV2?.reduce<Record<string, Service>>(
      (acc, service) => ({
        ...acc,
        [service.id!]: service,
      }),
      {},
    ) || {};

  const servicesWithEnrichedPolicy: Service[] = partialServicesV2.map(
    (service) => {
      return idServicesV2Map[service.id!] || service;
    },
  );

  return servicesWithEnrichedPolicy;
};
