import {
  SlotAvailability,
  LocationType,
} from '@wix/ambassador-availability-calendar/types';
import {
  Location,
  Service,
  LocationType as ServiceLocationType,
} from '@wix/ambassador-bookings-services-v2-service/types';
import { ReservedLocationIds } from '@wix/bookings-uou-types';

export const RESERVED_LOCATIONS = [
  ReservedLocationIds.OTHER_LOCATIONS,
] as const;

export const getCurrentSelectedLocations = (
  locations: Location[],
  savedLocationsIds: string[],
): string[] => {
  if (areAllLocationsSelected(savedLocationsIds)) {
    const allLocationsIds = locations.map((location) => location.business?.id!);
    return [...allLocationsIds, ...RESERVED_LOCATIONS];
  }
  return savedLocationsIds;
};

export const areAllLocationsSelected = (selectedLocations: string[]) => {
  return selectedLocations.length === 0;
};

export const isOtherLocationsSelected = (selectedLocations: string[]) => {
  return selectedLocations.includes(ReservedLocationIds.OTHER_LOCATIONS);
};

export const isAllBusinessLocationsSelected = (
  selectedLocations: string[],
  businessLocations: Location[],
) =>
  businessLocations.every((location) =>
    selectedLocations.includes(location.business?.id!),
  );

export const isOnlyBusinessLocationsSelected = (
  selectedLocations: string[],
) => {
  return (
    !areAllLocationsSelected(selectedLocations) &&
    !isOtherLocationsSelected(selectedLocations)
  );
};

export const filterServicesBySelectedLocations = (
  selectedLocations: string[],
  services: Service[],
) => {
  if (!areAllLocationsSelected(selectedLocations)) {
    return services.filter((service) => {
      return service.locations?.some((location) => {
        if (location.type === ServiceLocationType.BUSINESS) {
          return selectedLocations.includes(location.business?.id!);
        }
        return selectedLocations.includes(ReservedLocationIds.OTHER_LOCATIONS);
      });
    });
  }
  return services;
};

export const filterSlotsBySelectedLocations = (
  selectedLocations: string[],
  availableSlots?: SlotAvailability[],
) => {
  if (!areAllLocationsSelected(selectedLocations)) {
    return (
      availableSlots?.filter((slotAvailability) => {
        const location = slotAvailability?.slot?.location;
        if (location?.locationType === LocationType.OWNER_BUSINESS) {
          return selectedLocations.includes(location.id!);
        }
        return selectedLocations.includes(ReservedLocationIds.OTHER_LOCATIONS);
      }) || []
    );
  }
  return availableSlots;
};
